<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="650px"
      class="mx-auto my-12"
    >
      <v-card>
        <v-card-title>
          SMS
          <v-spacer></v-spacer>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="sendSms()"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.recipient"
                  :items="recipients"
                  item-text="name"
                  item-value="value"
                  label="Recipient"
                  :loading="isRecipientsLoading"
                  :rules="[v => !!v || 'Recipient is required']"
                  :error="form.errors.has('recipients')"
                  :error-messages="form.errors.get('recipients')"
                  outlined
                  required
                ></v-select>
                <small
                  v-show="form.errors.has('recipient')"
                  class="validation-error"
                >{{
                  form.errors.get('recipient')
                }}</small>
              </v-col>
              <v-expand-transition>
                <v-col
                  v-show="showActiveCustomersCheckbox"
                  cols="12"
                >
                  <v-row
                    no-gutters
                    align="center"
                    class="mb-4"
                  >
                    <v-col cols="auto">
                      <v-checkbox
                        v-model="form.active_customers"
                        label="Active customers"
                        class="mt-0"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-tooltip
                        max-width="300"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            color="primary"
                            class="ml-2"
                            v-bind="attrs"
                            style="cursor: pointer;"
                            v-on="on"
                          >
                            {{ icons.mdiInformationOutline }}
                          </v-icon>
                        </template>
                        <span>Check this box to send sms to only active customers</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-expand-transition>
              <v-expand-transition>
                <v-col
                  v-if="showSpecificUsersSelect"
                  cols="12"
                >
                  <customer-search-autocomplete
                    :form-errors="form.errors"
                    :initial-customer-id="userId"
                    :multiple="true"
                    @update="updateCustomerId"
                  />
                </v-col>
              </v-expand-transition>
              <v-expand-transition>
                <v-col
                  v-if="showSpecificPhoneNumberInput"
                  cols="12"
                >
                  <v-textarea
                    v-model="form.specific_phone_numbers"
                    label="Phone numbers"
                    :rules="showSpecificPhoneNumberInput ? [v => !!v || 'Phone number is required'] : []"
                    :error="form.errors.has('specific_phone_numbers')"
                    :error-messages="form.errors.get('specific_phone_numbers')"
                    rows="1"
                    row-height="5"
                    hint="Enter phone numbers separated by comma"
                    placeholder="07********, 07********"
                    auto-grow
                    outlined
                  ></v-textarea>
                </v-col>
              </v-expand-transition>
              <v-col cols="12">
                <v-textarea
                  v-model="form.message"
                  clearable
                  label="Type your message here"
                  outlined
                  auto-grow
                  :rules="message_rules"
                  :error="form.errors.has('message')"
                  :error-messages="form.errors.get('message')"
                  counter
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row
              class="mt-1 mb-2"
              justify="center"
            >
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    View Sms variables
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col>
                      <div class="grey--text">
                        Use this variables to personalize messages. Here are some of available variables that you can use.
                        Click on variable name to quickly append it to message
                      </div>
                      <v-row class="mt-2">
                        <v-col
                          cols="12"
                          class="pd-0 pt-0"
                        >
                          <div class="grey--text Caption">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  href="#"
                                  class="no-underline"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.prevent="appendToMessage('{first-name}')"
                                >{first-name}</a>
                              </template>
                              <span>Click to append to message</span>
                            </v-tooltip>
                            : Gets the specific customer first name
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          class="pd-0 pt-0"
                        >
                          <div class="grey--text Caption">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  href="#"
                                  class="no-underline"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.prevent="appendToMessage('{full-name}')"
                                >{full-name}</a>
                              </template>
                              <span>Click to append to message</span>
                            </v-tooltip>
                            : Gets the specific customer full name
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          class="pd-0 pt-0"
                        >
                          <div class="grey--text Caption">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  href="#"
                                  class="no-underline"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.prevent="appendToMessage('{account-number}')"
                                >{account-number}</a>
                              </template>
                              <span>Click to append to message</span>
                            </v-tooltip>
                            : Gets the specific customer account number
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          class="pd-0 pt-0"
                        >
                          <div class="grey--text Caption">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  href="#"
                                  class="no-underline"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.prevent="appendToMessage('{debt}')"
                                >{debt}</a>
                              </template>
                              <span>Click to append to message</span>
                            </v-tooltip>
                            : Gets the specific customer debt
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          class="pd-0 pt-0"
                        >
                          <div class="grey--text Caption">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  href="#"
                                  class="no-underline"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.prevent="appendToMessage('{expiry-date}')"
                                >{expiry-date}</a>
                              </template>
                              <span>Click to append to message</span>
                            </v-tooltip>
                            : Gets the specific customer expiry date
                          </div>
                        </v-col><v-col
                          cols="12"
                          class="pd-0 pt-0"
                        >
                          <div class="grey--text Caption">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  href="#"
                                  class="no-underline"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.prevent="appendToMessage('{paybill-number}')"
                                >{paybill-number}</a>
                              </template>
                              <span>Click to append to message</span>
                            </v-tooltip>
                            : Gets the paybill number
                          </div>
                        </v-col><v-col
                          cols="12"
                          class="pd-0 pt-0"
                        >
                          <div class="grey--text Caption">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  href="#"
                                  class="no-underline"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.prevent="appendToMessage('{company-name}')"
                                >{company
                                  -name}</a>
                              </template>
                              <span>Click to append to message</span>
                            </v-tooltip>
                            : Gets the company name
                          </div>
                        </v-col><v-col
                          cols="12"
                          class="pd-0 pt-0"
                        >
                          <div class="grey--text Caption">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  href="#"
                                  class="no-underline"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.prevent="appendToMessage('{company-phone-number}')"
                                >{company-phone-number}</a>
                              </template>
                              <span>Click to append to message</span>
                            </v-tooltip>
                            : Gets the company phone number
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          class="pd-0 pt-0"
                        >
                          <div class="grey--text Caption">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  href="#"
                                  class="no-underline"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.prevent="appendToMessage('{company-email}')"
                                >{company-email}</a>
                              </template>
                              <span>Click to append to message</span>
                            </v-tooltip>
                            : Gets the company email
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiDelete, mdiInformationOutline } from '@mdi/js'
import { mapGetters } from 'vuex'
import Form from 'vform'
import CustomerSearchAutocomplete from '@/components/partials/CustomerSearchAutocomplete.vue'

export default {
  components: {
    CustomerSearchAutocomplete,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: new Form({
        recipient: '',
        specific_recipients: '',
        specific_phone_numbers: '',
        active_customers: true,
        message: '',
        station_id: '',
      }),
      smsTemplateForm: new Form({
        message: '',
      }),
      isRecipientsLoading: false,
      isSmsTemplatesLoading: false,
      showSpecificUsersSelect: false,
      showSpecificPhoneNumberInput: false,
      showSaveMessageTemplateButton: false,
      isUsersLoading: false,
      users: [],
      users_search: null,
      messageTemplatesDeleting: [],
      message_rules: [
        v => !!v || 'Message is required',
      ],
      recipients: [],
      smsTemplates: [],
      icons: {
        mdiDelete,
        mdiInformationOutline,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    showActiveCustomersCheckbox() {
      // Check if this.form.recipient is not one of the specified values and is not empty
      return this.form.recipient && !['specific-customer', 'specific-phone-numbers', 'customers-with-debt', 'customers-with-no-internet'].includes(this.form.recipient)
    },
  },
  watch: {
    users_search(value) {
      this.getUsers(value)
    },
    'form.recipient': function () {
      if (this.form.recipient === 'specific-customer') {
        this.showSpecificUsersSelect = true
      } else {
        this.showSpecificUsersSelect = false
      }
      if (this.form.recipient === 'specific-phone-numbers') {
        this.showSpecificPhoneNumberInput = true
      } else {
        this.showSpecificPhoneNumberInput = false
      }
    },
    selectedStation() {
      this.form.station_id = this.selectedStation
    },
    showDialog(value) {
      if (value && this.users.length === 0) {
        this.getRecipients()
      }
    },
    userId(value) {
      if (value) {
        this.initializeDefaultUser(value)
      }
    },
  },
  mounted() {
    this.form.station_id = this.selectedStation
    if (this.userId) {
      this.initializeDefaultUser(this.userId)
    }

    // this.getSmsTemplates()
  },
  methods: {
    initializeDefaultUser(userId) {
      console.log('🚀 ~ file: SendSmsDialog.vue:328 ~ initializeDefaultUser ~ userId', userId)
      this.form.specific_recipients = userId
      this.form.recipient = 'specific-customer'
      this.showSpecificUsersSelect = true
    },
    getRecipients() {
      this.isRecipientsLoading = true
      axios
        .get('stations?perPage=all')
        .then(response => {
          this.recipients.push({ name: 'All customers', value: 'all' })
          this.recipients.push({ name: 'Customers with debt', value: 'customers-with-debt' })
          this.recipients.push({ name: 'Customers with no internet', value: 'customers-with-no-internet' })
          response.data.forEach(station => {
            this.recipients.push({ name: `${station.name} customers`, value: station.id })
          })
          this.recipients.push({ name: 'Specific customers', value: 'specific-customer' })
          this.recipients.push({ name: 'Specific phone numbers', value: 'specific-phone-numbers' })
          this.isRecipientsLoading = false
        })
        .catch(error => {
          this.isRecipientsLoading = false
          this.$toast.error(error.response.data.message)
        })
    },
    sendSms() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post('sms')
          .then(() => {
            this.$toast.success('Message sent successfully')
            this.$emit('sent')
            this.$refs.form.reset()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
    getUsers(value) {
      this.isUsersLoading = true
      axios
        .get(`customers?station_id=${this.selectedStation}&search=${value}&searchColumn=['name','users.phone_number']&perPage=20`)
        .then(response => {
          this.users = response.data.data
          this.isUsersLoading = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.$toast.error(error.response.data.message)
        })
    },
    getSmsTemplates() {
      this.isSmsTemplatesLoading = true
      axios
        .get('sms-templates')
        .then(response => {
          this.smsTemplates = response.data
          this.isSmsTemplatesLoading = false
        })
        .catch(error => {
          this.isSmsTemplatesLoading = false
          this.$toast.error(error.response.data.message)
        })
    },
    saveMessageTemplate() {
      this.smsTemplateForm
        .post('sms-templates')
        .then(() => {
          this.$toast.success('Message template saved successfully')
          this.showSaveMessageTemplateButton = false
          this.$refs.messageTemplateForm.reset()
          this.getSmsTemplates()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    deleteMessageTemplate(id) {
      this.messageTemplatesDeleting.push(id)
      axios
        .delete(`sms-templates/${id}`)
        .then(() => {
          this.messageTemplatesDeleting.pop(id)
          this.$toast.success('Message template deleted successfully')
          this.getSmsTemplates()
        })
        .catch(error => {
          this.messageTemplatesDeleting.pop(id)
          this.$toast.error(error.response.data.message)
        })
    },
    updateCustomerId(newCustomerId) {
      this.form.specific_recipients = newCustomerId
    },
    remove(item) {
      const index = this.form.specific_recipients.indexOf(item.name)
      if (index >= 0) this.form.specific_recipients.splice(index, 1)
    },
    appendToMessage(text) {
      if (this.form.message == null) {
        this.form.message = text
      } else {
        this.form.message += text
      }
    },
    setMessageTemplate(message) {
      this.form.message = message
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  min-height: 48px !important;
  height: unset !important;
}
</style>
